.varsCareDark() {
  --weui-BG-0: #111;
  --weui-BG-1: #1e1e1e;
  --weui-BG-2: #191919;
  --weui-BG-3: #202020;
  --weui-BG-4: #404040;
  --weui-BG-5: #2c2c2c;
  --weui-BLUE-100: #10aeff;
  --weui-BLUE-120: #0c8bcc;
  --weui-BLUE-170: #04344d;
  --weui-BLUE-80: #3fbeff;
  --weui-BLUE-90: #28b6ff;
  --weui-BLUE-BG-100: #48a6e2;
  --weui-BLUE-BG-110: #4095cb;
  --weui-BLUE-BG-130: #32749e;
  --weui-BLUE-BG-90: #5aafe4;
  --weui-BRAND-100: #07c160;
  --weui-BRAND-120: #059a4c;
  --weui-BRAND-170: #023a1c;
  --weui-BRAND-80: #38cd7f;
  --weui-BRAND-90: #20c770;
  --weui-BRAND-BG-100: #2aae67;
  --weui-BRAND-BG-110: #259c5c;
  --weui-BRAND-BG-130: #1d7a48;
  --weui-BRAND-BG-90: #3eb575;
  --weui-FG-0: rgba(255, 255, 255, 0.85);
  --weui-FG-0_5: rgba(255, 255, 255, 0.65);
  --weui-FG-1: rgba(255, 255, 255, 0.55);
  --weui-FG-2: rgba(255, 255, 255, 0.35);
  --weui-FG-3: rgba(255, 255, 255, 0.1);
  --weui-FG-4: rgba(255, 255, 255, 0.15);
  --weui-GLYPH-0: rgba(255, 255, 255, 0.85);
  --weui-GLYPH-1: rgba(255, 255, 255, 0.55);
  --weui-GLYPH-2: rgba(255, 255, 255, 0.35);
  --weui-GLYPH-WHITE-0: rgba(255, 255, 255, 0.85);
  --weui-GLYPH-WHITE-1: rgba(255, 255, 255, 0.55);
  --weui-GLYPH-WHITE-2: rgba(255, 255, 255, 0.35);
  --weui-GLYPH-WHITE-3: #fff;
  --weui-GREEN-100: #74a800;
  --weui-GREEN-120: #5c8600;
  --weui-GREEN-170: #233200;
  --weui-GREEN-80: #8fb933;
  --weui-GREEN-90: #82b01a;
  --weui-GREEN-BG-100: #789833;
  --weui-GREEN-BG-110: #6b882d;
  --weui-GREEN-BG-130: #65802b;
  --weui-GREEN-BG-90: #85a247;
  --weui-INDIGO-100: #1196ff;
  --weui-INDIGO-120: #0d78cc;
  --weui-INDIGO-170: #052d4d;
  --weui-INDIGO-80: #40abff;
  --weui-INDIGO-90: #28a0ff;
  --weui-INDIGO-BG-100: #0d78cc;
  --weui-INDIGO-BG-110: #0b6bb7;
  --weui-INDIGO-BG-130: #09548f;
  --weui-INDIGO-BG-90: #2585d1;
  --weui-LIGHTGREEN-100: #3eb575;
  --weui-LIGHTGREEN-120: #31905d;
  --weui-LIGHTGREEN-170: #123522;
  --weui-LIGHTGREEN-80: #64c390;
  --weui-LIGHTGREEN-90: #51bc83;
  --weui-LIGHTGREEN-BG-100: #31905d;
  --weui-LIGHTGREEN-BG-110: #2c8153;
  --weui-LIGHTGREEN-BG-130: #226541;
  --weui-LIGHTGREEN-BG-90: #31905d;
  --weui-LINK-100: #7d90a9;
  --weui-LINK-120: #647387;
  --weui-LINK-170: #252a32;
  --weui-LINK-80: #97a6ba;
  --weui-LINK-90: #899ab1;
  --weui-LINKFINDER-100: #dee9ff;
  --weui-MATERIAL-ATTACHMENTCOLUMN: rgba(32, 32, 32, 0.93);
  --weui-MATERIAL-NAVIGATIONBAR: rgba(18, 18, 18, 0.9);
  --weui-MATERIAL-REGULAR: rgba(37, 37, 37, 0.6);
  --weui-MATERIAL-THICK: rgba(34, 34, 34, 0.9);
  --weui-MATERIAL-THIN: rgba(245, 245, 245, 0.4);
  --weui-MATERIAL-TOOLBAR: rgba(35, 35, 35, 0.93);
  --weui-ORANGE-100: #c87d2f;
  --weui-ORANGE-120: #a06425;
  --weui-ORANGE-170: #3b250e;
  --weui-ORANGE-80: #d39758;
  --weui-ORANGE-90: #cd8943;
  --weui-ORANGE-BG-100: #bb6000;
  --weui-ORANGE-BG-110: #a85600;
  --weui-ORANGE-BG-130: #824300;
  --weui-ORANGE-BG-90: #c1701a;
  --weui-ORANGERED-100: #ff6146;
  --weui-OVERLAY: rgba(0, 0, 0, 0.8);
  --weui-OVERLAY-WHITE: rgba(242, 242, 242, 0.8);
  --weui-PURPLE-100: #8183ff;
  --weui-PURPLE-120: #6768cc;
  --weui-PURPLE-170: #26274c;
  --weui-PURPLE-80: #9a9bff;
  --weui-PURPLE-90: #8d8fff;
  --weui-PURPLE-BG-100: #6768cc;
  --weui-PURPLE-BG-110: #5c5db7;
  --weui-PURPLE-BG-130: #48498f;
  --weui-PURPLE-BG-90: #7677d1;
  --weui-RED-100: #fa5151;
  --weui-RED-120: #c84040;
  --weui-RED-170: #4b1818;
  --weui-RED-80: #fb7373;
  --weui-RED-90: #fa6262;
  --weui-RED-BG-100: #cf5148;
  --weui-RED-BG-110: #ba4940;
  --weui-RED-BG-130: #913832;
  --weui-RED-BG-90: #d3625a;
  --weui-SECONDARY-BG: rgba(255, 255, 255, 0.15);
  --weui-SEPARATOR-0: rgba(255, 255, 255, 0.05);
  --weui-SEPARATOR-1: rgba(255, 255, 255, 0.15);
  --weui-STATELAYER-HOVERED: rgba(0, 0, 0, 0.02);
  --weui-STATELAYER-PRESSED: rgba(255, 255, 255, 0.1);
  --weui-STATELAYER-PRESSEDSTRENGTHENED: rgba(255, 255, 255, 0.2);
  --weui-YELLOW-100: #cc9c00;
  --weui-YELLOW-120: #a37c00;
  --weui-YELLOW-170: #3d2f00;
  --weui-YELLOW-80: #d6af33;
  --weui-YELLOW-90: #d1a519;
  --weui-YELLOW-BG-100: #bf9100;
  --weui-YELLOW-BG-110: #ab8200;
  --weui-YELLOW-BG-130: #866500;
  --weui-YELLOW-BG-90: #c59c1a;
  --weui-FG-HALF: rgba(255, 255, 255, 0.65);
  --weui-RED: #fa5151;
  --weui-ORANGERED: #ff6146;
  --weui-ORANGE: #c87d2f;
  --weui-YELLOW: #cc9c00;
  --weui-GREEN: #74a800;
  --weui-LIGHTGREEN: #3eb575;
  --weui-TEXTGREEN: #259c5c;
  --weui-BRAND: #07c160;
  --weui-BLUE: #10aeff;
  --weui-INDIGO: #1196ff;
  --weui-PURPLE: #8183ff;
  --weui-LINK: #7d90a9;
  --weui-REDORANGE: #ff6146;
  --weui-TAG-BACKGROUND-BLACK: rgba(255, 255, 255, 0.05);
  --weui-FG: #fff;
  --weui-WHITE: rgba(255, 255, 255, 0.8);
  --weui-FG-5: rgba(255, 255, 255, 0.1);
  --weui-TAG-BACKGROUND-ORANGE: rgba(250, 157, 59, 0.1);
  --weui-TAG-BACKGROUND-GREEN: rgba(6, 174, 86, 0.1);
  --weui-TAG-TEXT-RED: rgba(250, 81, 81, 0.6);
  --weui-TAG-BACKGROUND-RED: rgba(250, 81, 81, 0.1);
  --weui-TAG-BACKGROUND-BLUE: rgba(16, 174, 255, 0.1);
  --weui-TAG-TEXT-ORANGE: rgba(250, 157, 59, 0.6);
  --weui-BG: #000;
  --weui-TAG-TEXT-GREEN: rgba(6, 174, 86, 0.6);
  --weui-TAG-TEXT-BLUE: rgba(16, 174, 255, 0.6);
  --weui-TAG-TEXT-BLACK: rgba(255, 255, 255, 0.5);
}
