.varsLight() {
  --weui-BG-0: #ededed;
  --weui-BG-1: #f7f7f7;
  --weui-BG-2: #fff;
  --weui-BG-3: #f7f7f7;
  --weui-BG-4: #4c4c4c;
  --weui-BG-5: #fff;
  --weui-BLUE-100: #10aeff;
  --weui-BLUE-120: #3fbeff;
  --weui-BLUE-170: #b7e6ff;
  --weui-BLUE-80: #0c8bcc;
  --weui-BLUE-90: #0e9ce6;
  --weui-BLUE-BG-100: #48a6e2;
  --weui-BLUE-BG-110: #5aafe4;
  --weui-BLUE-BG-130: #7fc0ea;
  --weui-BLUE-BG-90: #4095cb;
  --weui-BRAND-100: #07c160;
  --weui-BRAND-120: #38cd7f;
  --weui-BRAND-170: #b4ecce;
  --weui-BRAND-80: #059a4c;
  --weui-BRAND-90: #06ae56;
  --weui-BRAND-BG-100: #2aae67;
  --weui-BRAND-BG-110: #3eb575;
  --weui-BRAND-BG-130: #69c694;
  --weui-BRAND-BG-90: #259c5c;
  --weui-FG-0: rgba(0, 0, 0, 0.9);
  --weui-FG-0_5: rgba(0, 0, 0, 0.9);
  --weui-FG-1: rgba(0, 0, 0, 0.55);
  --weui-FG-2: rgba(0, 0, 0, 0.3);
  --weui-FG-3: rgba(0, 0, 0, 0.1);
  --weui-FG-4: rgba(0, 0, 0, 0.15);
  --weui-GLYPH-0: rgba(0, 0, 0, 0.9);
  --weui-GLYPH-1: rgba(0, 0, 0, 0.55);
  --weui-GLYPH-2: rgba(0, 0, 0, 0.3);
  --weui-GLYPH-WHITE-0: rgba(255, 255, 255, 0.8);
  --weui-GLYPH-WHITE-1: rgba(255, 255, 255, 0.5);
  --weui-GLYPH-WHITE-2: rgba(255, 255, 255, 0.3);
  --weui-GLYPH-WHITE-3: #fff;
  --weui-GREEN-100: #91d300;
  --weui-GREEN-120: #a7db33;
  --weui-GREEN-170: #def1b3;
  --weui-GREEN-80: #74a800;
  --weui-GREEN-90: #82bd00;
  --weui-GREEN-BG-100: #96be40;
  --weui-GREEN-BG-110: #a0c452;
  --weui-GREEN-BG-130: #b5d179;
  --weui-GREEN-BG-90: #86aa39;
  --weui-INDIGO-100: #1485ee;
  --weui-INDIGO-120: #439df1;
  --weui-INDIGO-170: #b8daf9;
  --weui-INDIGO-80: #106abe;
  --weui-INDIGO-90: #1277d6;
  --weui-INDIGO-BG-100: #2b77bf;
  --weui-INDIGO-BG-110: #3f84c5;
  --weui-INDIGO-BG-130: #6ba0d2;
  --weui-INDIGO-BG-90: #266aab;
  --weui-LIGHTGREEN-100: #95ec69;
  --weui-LIGHTGREEN-120: #aaef87;
  --weui-LIGHTGREEN-170: #def9d1;
  --weui-LIGHTGREEN-80: #77bc54;
  --weui-LIGHTGREEN-90: #85d35e;
  --weui-LIGHTGREEN-BG-100: #72cf60;
  --weui-LIGHTGREEN-BG-110: #80d370;
  --weui-LIGHTGREEN-BG-130: #9cdd90;
  --weui-LIGHTGREEN-BG-90: #66b956;
  --weui-LINK-100: #576b95;
  --weui-LINK-120: #7888aa;
  --weui-LINK-170: #ccd2de;
  --weui-LINK-80: #455577;
  --weui-LINK-90: #4e6085;
  --weui-LINKFINDER-100: #002666;
  --weui-MATERIAL-ATTACHMENTCOLUMN: rgba(245, 245, 245, 0.95);
  --weui-MATERIAL-NAVIGATIONBAR: rgba(237, 237, 237, 0.94);
  --weui-MATERIAL-REGULAR: rgba(247, 247, 247, 0.3);
  --weui-MATERIAL-THICK: rgba(247, 247, 247, 0.8);
  --weui-MATERIAL-THIN: rgba(255, 255, 255, 0.2);
  --weui-MATERIAL-TOOLBAR: rgba(246, 246, 246, 0.82);
  --weui-ORANGE-100: #fa9d3b;
  --weui-ORANGE-120: #fbb062;
  --weui-ORANGE-170: #fde1c3;
  --weui-ORANGE-80: #c87d2f;
  --weui-ORANGE-90: #e08c34;
  --weui-ORANGE-BG-100: #ea7800;
  --weui-ORANGE-BG-110: #ec8519;
  --weui-ORANGE-BG-130: #f0a04d;
  --weui-ORANGE-BG-90: #d26b00;
  --weui-ORANGERED-100: #ff6146;
  --weui-OVERLAY: rgba(0, 0, 0, 0.5);
  --weui-OVERLAY-WHITE: rgba(242, 242, 242, 0.8);
  --weui-PURPLE-100: #6467f0;
  --weui-PURPLE-120: #8385f3;
  --weui-PURPLE-170: #d0d1fa;
  --weui-PURPLE-80: #5052c0;
  --weui-PURPLE-90: #595cd7;
  --weui-PURPLE-BG-100: #6769ba;
  --weui-PURPLE-BG-110: #7678c1;
  --weui-PURPLE-BG-130: #9496ce;
  --weui-PURPLE-BG-90: #5c5ea7;
  --weui-RED-100: #fa5151;
  --weui-RED-120: #fb7373;
  --weui-RED-170: #fdcaca;
  --weui-RED-80: #c84040;
  --weui-RED-90: #e14949;
  --weui-RED-BG-100: #cf5148;
  --weui-RED-BG-110: #d3625a;
  --weui-RED-BG-130: #dd847e;
  --weui-RED-BG-90: #b94840;
  --weui-SECONDARY-BG: rgba(0, 0, 0, 0.05);
  --weui-SEPARATOR-0: rgba(0, 0, 0, 0.1);
  --weui-SEPARATOR-1: rgba(0, 0, 0, 0.15);
  --weui-STATELAYER-HOVERED: rgba(0, 0, 0, 0.02);
  --weui-STATELAYER-PRESSED: rgba(0, 0, 0, 0.1);
  --weui-STATELAYER-PRESSEDSTRENGTHENED: rgba(0, 0, 0, 0.2);
  --weui-YELLOW-100: #ffc300;
  --weui-YELLOW-120: #ffcf33;
  --weui-YELLOW-170: #ffecb2;
  --weui-YELLOW-80: #cc9c00;
  --weui-YELLOW-90: #e6af00;
  --weui-YELLOW-BG-100: #efb600;
  --weui-YELLOW-BG-110: #f0bd19;
  --weui-YELLOW-BG-130: #f3cc4d;
  --weui-YELLOW-BG-90: #d7a400;
  --weui-FG-HALF: rgba(0, 0, 0, 0.9);
  --weui-RED: #fa5151;
  --weui-ORANGERED: #ff6146;
  --weui-ORANGE: #fa9d3b;
  --weui-YELLOW: #ffc300;
  --weui-GREEN: #91d300;
  --weui-LIGHTGREEN: #95ec69;
  --weui-TEXTGREEN: #06ae56;
  --weui-BRAND: #07c160;
  --weui-BLUE: #10aeff;
  --weui-INDIGO: #1485ee;
  --weui-PURPLE: #6467f0;
  --weui-LINK: #576b95;
  --weui-TAG-TEXT-ORANGE: #fa9d3b;
  --weui-TAG-TEXT-GREEN: #06ae56;
  --weui-TAG-TEXT-BLUE: #10aeff;
  --weui-REDORANGE: #ff6146;
  --weui-TAG-TEXT-BLACK: rgba(0, 0, 0, 0.5);
  --weui-TAG-BACKGROUND-BLACK: rgba(0, 0, 0, 0.05);
  --weui-WHITE: #fff;
  --weui-BG: #fff;
  --weui-FG: #000;
  --weui-FG-5: rgba(0, 0, 0, 0.05);
  --weui-TAG-BACKGROUND-ORANGE: rgba(250, 157, 59, 0.1);
  --weui-TAG-BACKGROUND-GREEN: rgba(6, 174, 86, 0.1);
  --weui-TAG-TEXT-RED: rgba(250, 81, 81, 0.6);
  --weui-TAG-BACKGROUND-RED: rgba(250, 81, 81, 0.1);
  --weui-TAG-BACKGROUND-BLUE: rgba(16, 174, 255, 0.1);
}
